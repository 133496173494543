import React from 'react';
import { Navigate } from 'react-router-dom';
import AdminServices from '../services/AdminServices';

const AdminPublicRoute = ({ element: Component, ...rest }) => {
    const admin = AdminServices.getAdminToken(); 
    return admin ? <Navigate to="/admin/dashboard" replace /> : <Component {...rest} />;
};

export default AdminPublicRoute;
