import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('user');
        // console.log('Stored Token:', storedToken);
        if (storedToken) {
            setToken(JSON.parse(storedToken));
        }
    }, []);


    const saveToken = (userToken) => {
        setToken(userToken);
        localStorage.setItem('user', JSON.stringify(userToken));
    };

    const logout = () => {
        setToken(null);
        localStorage.removeItem('user');
    };

    return (
        <AuthContext.Provider value={{ token, saveToken, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
