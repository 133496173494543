import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import ProfileRequestNotification from '../components/ProfileRequestNotification';
import { ToastContainer, toast } from 'react-toastify';
import useNotificationServices from '../services/NotificationsServices';

function NotificationsScreen() {
    const location = useLocation();

    const [notifications, setNotifications] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const { getNotifications } = useNotificationServices()

    const getUserNotifications = async () => {
        try {
            const response = await getNotifications(100);
            // console.log("Get Notifications: ", response);
            setNotifications(response.data);
        } catch (error) {
            console.error("Error fetching notifications:", error);
            setError("Failed to load notifications.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserNotifications();
    }, []);

    return (
        <div>
            <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
                <ToastContainer toastClassName='toaster' />
                <div
                    className={`screen_container d-flex align-items-center  ${loading && "justify-content-center"}`}
                    style={{ height: loading ? "80vh" : "auto", minHeight: "80vh" }}
                >
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className='w-100'>
                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                <div className='w-50'>
                                    <Header />
                                </div>
                            </div>
                            {error && <p className='text-danger text-center' style={{ fontSize: '12px' }}>{error}</p>}
                            {notifications ? (
                                notifications.map((notification) => (
                                    <ProfileRequestNotification
                                        key={notification.id}
                                        notification={notification}
                                        user={location.state?.user}
                                    />
                                ))
                            ) : (
                                <p className='text-white text-center'>No notifications available.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
}

export default NotificationsScreen;
