import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import facebook from '../assets/icons/facebook.png';
import google from '../assets/icons/google.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoadingIndicator from '../components/LoadingIndicator';
import useAuthServices from '../services/AuthServices';
import useSocialAuth from '../functions/SocialAuth';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const { login } = useAuthServices();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const idParam = params.get('uuid');
    if (idParam) {
      // console.log(idParam);
      setId(idParam);
    }
  }, [location]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage('');
  };

  const onSignin = async () => {
    setLoading(true);
    try {
      if (email.trim() === "" || password.trim() === "") {
        setErrorMessage("Please fill in the required fields!");
        return;
      }

      const response = await login(email, password);
      // console.log(response);
      if (response.success) {
        setTimeout(
          () => {
            // toast.success("You have logged in successfully!");
            if (id) {
              navigate(`/deceased-profile?uuid=${id}`);
            } else {
              navigate(`/profile`);
            }
          }, 1000
        )
      } else {
        setErrorMessage("Invalid login credentials. Please try again.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      const errorMessage = error.response?.data?.statusMessage || "Login failed!";
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const { FBlogin, googleLogin } = useSocialAuth();

  return (
    <div className='main_container' style={{ minHeight: "100vh" }}>
      <div className='screen_container justify-content-between'>
        <Header />
        <ToastContainer toastClassName='toaster' />
        <div>
          <p className='text-black text-center my-2'>Log in</p>
          {errorMessage && (
            <p className='text-danger mb-2 text-center' style={{ fontSize: '14px' }}>
              {errorMessage}
            </p>
          )}
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1'>
            <Form onSubmit={(e) => { e.preventDefault(); onSignin(); }}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  className='input'
                  value={email}
                  onChange={handleEmailChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <InputGroup>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    className='input'
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <Button
                    className='input'
                    id="forgetButton"
                    type="button"
                    onClick={() => navigate('/forget-password')}
                  >
                    <p className='forgetButton'>Forget?</p>
                  </Button>
                </InputGroup>
              </Form.Group>
              <div>
                {loading ? (
                  <LoadingIndicator />
                ) : (
                  <Button
                    className="w-100 actionBtn mb-1"
                    type="submit"
                  >
                    Sign in
                  </Button>
                )}
              </div>
            </Form>
            <p className='text-black text-center my-2'>or Continue with</p>
            <div className='d-flex justify-content-between'>
              <Button
                className='w-50 fbbg me-4'
                id="facebook"
                type="button"
                onClick={async () => {
                  try {
                    const fb = await FBlogin();
                    if (fb.success) {
                      // toast.success("You have logged in successfully!");
                      if (id) {
                        navigate(`/deceased-profile?uuid=${id}`);
                      } else {
                        navigate(`/profile`);
                      }
                    } else {
                      setErrorMessage(fb.error)
                    }
                  } catch (error) {
                    setErrorMessage("Login with Facebook failed!");
                  }
                }}
              >
                <img src={facebook} alt='Facebook' className='me-1 mb-1' /> Facebook
              </Button>
              <Button
                className='w-50 googlebg'
                id="google"
                type="button"
                onClick={async () => {
                  try {
                    const googleResponse = await googleLogin();
                    if (googleResponse.success) {
                      // toast.success("You have logged in successfully!");
                      if (id) {
                        navigate(`/deceased-profile?uuid=${id}`);
                      } else {
                        navigate(`/profile`);
                      }
                    } else {
                      setErrorMessage(googleResponse.error)
                    }
                  } catch (error) {
                    setErrorMessage("Login with Google failed!");
                  }
                }}
              >
                <img src={google} alt='Google' className='me-1 mb-1' /> Google
              </Button>
            </div>
          </div>
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1 mt-3'>
            <p className='text-black text-center mb-2'>Not Registered Yet?</p>
            <Button
              className='w-100 signupbtn mt-2 text-black'
              id="signupbtn"
              type="button"
              onClick={() => {
                if (id) {
                  navigate(`/signup?uuid=${id}`)
                } else {
                  navigate('/signup')
                }
              }}
            >
              Sign up
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
