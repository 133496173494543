import React, { useState } from 'react';
import Header from '../components/Header';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useLocation } from 'react-router-dom'
import useAuthServices from '../services/AuthServices';

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { resetPassword } = useAuthServices();
  const navigate = useNavigate();
  const location = useLocation();

  // Extract email and key from query params
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const key = queryParams.get('key');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
    setSuccess('');
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!password) {
      setError('Password is required.');
      setLoading(false)

      return;
    }
    if (!confirmpassword) {
      setError('Confirm Password is required.');
      setLoading(false)

      return;
    }
    if (password !== confirmpassword) {
      setError('Password Mismatch');
      setLoading(false)

      return;
    }

    try {
      const response = await resetPassword(email, password, confirmpassword, key);

      if (response.success) {
        setSuccess('Password Changed Successfully.');
        setPassword('');
        setConfirmPassword('');
        // setTimeout(() => navigate('/login'), 2000);
        setLoading(false)

      } else {
        const errorMessage =
          typeof response.error === 'object'
            ? response.error.statusMessage || 'Unexpected error occurred.'
            : response.error || 'Failed to reset password. Please try again.';
        setError(errorMessage);
        
        setLoading(false)

      }
    } catch (error) {
      setError('Unexpected error occurred. Please try again.');
      setLoading(false)

      console.error(error);
    }
  };

  return (
    <div className='main_container' style={{ minHeight: "100vh" }}>
      <div className='screen_container justify-content-between'>
        <Header />
        <div>
          <p className='text-black text-center my-2'>Reset Password</p>
          {error && <p className='text-danger text-center' style={{ fontSize: '12px' }}>{error}</p>}
          {success && <p className='text-success text-center' style={{ fontSize: '14px' }}>{success}</p>}
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1'>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Control type="password" placeholder="New Password" className='input' value={password} onChange={handlePasswordChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="confirmpassword">
                <Form.Control type="password" placeholder="Confirm New Password" className='input' value={confirmpassword} onChange={handleConfirmPasswordChange} />
              </Form.Group>
              <Button className="w-100 actionBtn mb-1" type="submit">
                Reset Password
              </Button>
            </Form>
          </div>
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1 mt-3'>
            <p className='text-black text-center mb-2'>Back to Login?</p>
            <Button className='w-100 signupbtn mt-2 text-black' id="signupbtn" type="button" onClick={() => navigate('/login')}>
              Sign in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
