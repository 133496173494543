import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import { toast } from 'react-toastify';


const API_URL = `${BASE_URL}/api`;

const loginAdmin = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/Accounts/Login`, {
            "email": email, "password": password, "role": 1
        });

        if (response.data.access_token) {
            try { localStorage.setItem('admin', JSON.stringify(response)); localStorage.setItem('user', JSON.stringify(response.data)); } catch (e) { console.error(e) } finally {
                return { success: true };
            };
        }
    } catch (error) {
        console.error("Error during Admin login:", error);
        const errorMsg = error.response ? error.response.data : 'Unexpected error occured while deleting the QR Code. Try again!';
        // toast.error("Admin Login failed.");
        return { success: false, error: errorMsg };
    }
};

const deleteQRCode = async (qrCodeId) => {
    const token = await AdminServices.getAdminToken();

    if (!token) {
        logoutAdmin();

        return false;
    }

    try {
        const response = await axios.delete(
            `${API_URL}/QRCode/DeleteQRCode?uniqueIdentifier=${qrCodeId}`,
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );

        return { success: true, data: response.data };
    } catch (error) {
        console.error('Error deleting QR Code:', error);
        const errorMsg = error.response ? error.response.data : 'Unexpected error occured while deleting the QR Code. Try again!';
        return { success: true, error: errorMsg };
    }
};

const getDashboardCounts = async () => {
    const token = AdminServices.getAdminToken();

    if (!token) {
        AdminServices.logoutAdmin();
        return false;
    }

    try {
        const response = await axios.get(`${API_URL}/Dashboard/GetDashboardCounts`, {
            headers: {
                Authorization: `Bearer ${token.data.access_token}`,
                'accept': 'text/plain',
            },
        });

        return { success: true, data: response.data };
    } catch (error) {
        console.error('Error fetching dashboard counts:', error);
        const errorMsg = error.response ? error.response.data : 'Unexpected error occurred. Try again!';
        // toast.error(errorMsg);
        return { success: false, error: errorMsg };
    }
};

const bulkDeleteQRCodes = async (qrCodeIds) => {
    const token = AdminServices.getAdminToken();

    if (!token) {
        AdminServices.logoutAdmin();
        // toast.error("User is not authenticated.");
        return false;
    }

    console.log(token.data.access_token);

    try {
        const response = await axios.delete(
            `${API_URL}/QRCode/BulkDeleteQRCodes`,
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`,
                    'Content-Type': 'application/json-patch+json',
                },
                data: qrCodeIds,
            }
        );

        return { success: true, data: response.data };
    } catch (error) {
        console.error("Error during bulk deletion of QR codes:", error);
        const errorMsg = error.response ? error.response.data : 'Unexpected error occurred. Try again!';
        // toast.error(errorMsg);
        return { success: false, error: errorMsg };
    }
};



const getAdminToken = () => {
    try {
        return JSON.parse(localStorage.getItem('admin'));
    } catch (error) {
        console.error("Error getting token:", error);
        return null;
    }
};

const logoutAdmin = () => {
    localStorage.removeItem('admin');
};

const createQRCodes = async (numberOfQRCode) => {
    const token = await AdminServices.getAdminToken();

    try {
        const response = await axios.post(`${API_URL}/QRCode/CreateQRCode?numberOfQRCode=${numberOfQRCode}`, null, {
            headers: {
                Authorization: `Bearer ${token.data.access_token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating QR codes:', error);
        throw error;
    }
};

const getAdminDetails = async (navigate) => {
    const token = await AdminServices.getAdminToken();

    if (!token) {
        AdminServices.logoutAdmin();
        navigate("/admin/login");
        // toast.error("Admin is not authenticated.");
        return;
    }

    try {
        const response = await axios.get(`${API_URL}/Accounts/GetLoginUserDetail`, {
            headers: {
                Authorization: `Bearer ${token.data.access_token}`,
            },
        });

        if (response) {
            return response;
        }
    } catch (e) {
        console.error("Error fetching Admin details:", e);
        AdminServices.logout();
        navigate("/admin/login");
        setTimeout(() => {
            // toast.error("Token has expired.");
        }, 300);
    }
};

const GetAllQrCodes = async (pgno) => {
    const token = await AdminServices.getAdminToken();

    try {
        const response = await axios.post(
            `${API_URL}/QRCode/GetAllQrCodes`,
            {
                pageNumber: pgno,
                pageSize: 100,
            },
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error getting qrcodes:', error);
        return false;
    }
};

const DownloadAllQrCodes = async (pgno, date) => {
    const token = await AdminServices.getAdminToken();

    try {
        const response = await axios.post(
            `${API_URL}/QRCode/DownloadImages`,
            {
                pageNumber: pgno,
                pageSize: 50,
                startDate: date,
                endDate: date,
            },
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`,
                },
                responseType: 'blob',
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const now = new Date();
        const dateString = now.toISOString().replace(/T/, '_').replace(/\..+/, '');
        link.setAttribute('download', `qrcodes_${dateString}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), 100);

        return true;
    } catch (error) {
        console.error('Error downloading QR codes:', error);
        return false;
    }
};

const GetALLUsers = async (pgno) => {
    const token = await AdminServices.getAdminToken();

    try {
        const response = await axios.post(
            `${API_URL}/Accounts/GetAllUsers`,
            {
                pageNumber: pgno,
                pageSize: 50,
            },
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error getting users:', error);
        return false;
    }
};

const deleteUser = async (id) => {
    const token = await AdminServices.getAdminToken();

    try {
        const response = await axios.delete(
            `${API_URL}/Accounts/DeleteUser?UserId=${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error deleting users:', error);
        return false;
    }
};

const GetALLMemorialProfiles = async (pgno) => {
    const token = await AdminServices.getAdminToken();

    try {
        const response = await axios.post(
            `${API_URL}/Medallion/GetAllMedallion`,
            {
                pageNumber: pgno,
                pageSize: 50,
            },
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error getting memorial profiles:', error);
        return false;
    }
};

const updateProfile = async (id, profileData) => {
    // console.log(id, profileData);
    const token = AdminServices.getAdminToken();
    if (!token) {
        AdminServices.logoutAdmin();
        // toast.error("User is not authenticated.");
        return;
    }

    try {
        const response = await axios.post(`${API_URL}/Accounts/UpdateProfile`, { ...profileData, id }, {
            headers: {
                'Content-Type': 'application/json-patch+json',
                Authorization: `Bearer ${token.data.access_token}`
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error updating profile:", error)
        return false;
    }
};

const uploadFile = async (file) => {
    const token = AdminServices.getAdminToken();
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(`${API_URL}/FileUpload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token.data.access_token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error uploading file:', error);
        return false;
    }
};

const updatePassword = async (userId, oldPassword, newPassword, confirmPassword) => {
    const token = AdminServices.getAdminToken();
    try {
        const response = await axios.post(`${API_URL}/Accounts/UpdatePassword`, {
            userId,
            oldPassword,
            newPassword,
            confirmPassword
        }, {
            headers: {
                Authorization: `Bearer ${token.data.access_token}`,
                'Content-Type': 'application/json-patch+json'
            }
        });
        return response.data;
    } catch (error) {
        console.error(error.response ? error.response.data : 'Error updating password');
    }
};

const bulkDownloadQRCodes = async (uniqueIdentifiers) => {
    const token = await AdminServices.getAdminToken();

    if (!token) {
        AdminServices.logoutAdmin();
        // toast.error("User is not authenticated.");
        return false;
    }

    try {
        const response = await axios.post(
            `${API_URL}/QRCode/BulkDownloadQRCodes`,
            { uniqueIdentifiers },
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`,
                    'Content-Type': 'application/json-patch+json',
                },
                responseType: 'blob',
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const now = new Date();
        const dateString = now.toISOString().replace(/T/, '_').replace(/\..+/, '');
        link.setAttribute('download', `Bulk_QRCodes_${dateString}.zip`);

        document.body.appendChild(link);
        link.click();
        link.remove();

        setTimeout(() => window.URL.revokeObjectURL(url), 100);

        return { success: true };
    } catch (error) {
        console.error('Error downloading QR codes in bulk:', error);
        const errorMsg = error.response ? error.response.data : 'Unexpected error occurred. Try again!';
        // toast.error(errorMsg);
        return { success: false, error: errorMsg };
    }
};




const AdminServices = {
    createQRCodes,
    loginAdmin,
    getAdminToken,
    logoutAdmin,
    getAdminDetails,
    GetAllQrCodes,
    GetALLUsers,
    updateProfile,
    uploadFile,
    updatePassword,
    GetALLMemorialProfiles,
    DownloadAllQrCodes,
    deleteQRCode,
    deleteUser,
    bulkDeleteQRCodes,
    getDashboardCounts,
    bulkDownloadQRCodes
};

export default AdminServices;
