import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthServices from '../services/AuthServices';

const PrivateRoute = ({ element: Component, ...rest }) => {
    const { getToken } = useAuthServices()
    const user = getToken();

    // console.log("User private", user);

    return user ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default PrivateRoute;
