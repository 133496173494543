import React, { useState, useRef } from "react";
import styled from "styled-components";
import { BASE_URL } from "../../services/BASE_URL";
import cross from '../../assets/icons/cross.png';

// Styles
const GridWrapper = styled.div`
  margin-block: 30px;
  width: 100%;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(3, 1fr);

  img, video {
    width: 100%;
    object-fit: cover;
    height: 200px;
    cursor: pointer; /* Added cursor pointer for better UX */
  }

  .video-section {
    position: relative;
    height: 200px;
  }

  .video-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
`;

const Overlay = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;

  img, video {
    max-width: 90%;
    max-height: 90%;
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
`;

function PostGrid({ stories }) {
  const [selectedStory, setSelectedStory] = useState(null);

  const handleStoryClick = (story) => {
    setSelectedStory(story);
  };

  const closeOverlay = () => {
    setSelectedStory(null);
  };

  return (
    <>
      <GridWrapper>
        {stories.map((story, index) => (
          story.filePath.match(/\.(jpeg|jpg|gif|png)$/i) ? (
            <img
              key={index}
              src={`${BASE_URL}${story.filePath}`}
              alt={`post ${index}`}
              onClick={() => handleStoryClick(story)}
            />
          ) : (
            <div key={index} className='video-section'>
              <video
                src={`${BASE_URL}${story.filePath}`}
                onClick={() => handleStoryClick(story)}
              />
              <div className='video-overlay' onClick={() => handleStoryClick(story)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-play-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
                </svg>
              </div>
            </div>
          )
        ))}
      </GridWrapper>

      <Overlay isOpen={!!selectedStory} onClick={closeOverlay}>
        {selectedStory && (
          <>
            {selectedStory.filePath.match(/\.(jpeg|jpg|gif|png)$/i) ? (
              <img src={`${BASE_URL}${selectedStory.filePath}`} alt="Selected post" />
            ) : (
              <video src={`${BASE_URL}${selectedStory.filePath}`} controls autoPlay />
            )}
            <div className="close-icon" onClick={closeOverlay}>
              <img src={cross} alt="Close" style={{ height: 20, width: 20 }} />
            </div>
          </>
        )}
      </Overlay>
    </>
  );
}

export default PostGrid;
