import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCFxU9qVTomjJl6wXwIRrCM62Q8UzsdK_k",
  authDomain: "remeber-me-83d13.firebaseapp.com",
  projectId: "remeber-me-83d13",
  storageBucket: "remeber-me-83d13.appspot.com",
  messagingSenderId: "668003343734",
  appId: "1:668003343734:web:6670d6a863c3d391046e57",
  measurementId: "G-WE70D03SKP"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
