import React, { useState } from "react";
import styled from "styled-components";
import ProfileDetails from "./ProfileDetails";
import Tabs from "./Tabs";
import PostGrid from "./PostGrid";
import AboutMe from "./AboutMe";
import Tributes from "./Tributes";

const ProfileWrapper = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

function Profile({ medallion, stories, isUser, tributes, user }) {
  // console.log("User Get is ",user);
  const [activeTab, setActiveTab] = useState("About Me");
  return (
    <ProfileWrapper>
      <ProfileDetails medallion={medallion} isUser={isUser}  />
      <Tabs activeTab={activeTab} setActiveTab={(e) => { setActiveTab(e) }} />
      {
        activeTab === "About Me" ? <AboutMe medallion={medallion} /> : activeTab === "Media" ? <PostGrid stories={stories} /> : <Tributes medallionId={medallion.id} user={user} tributes={tributes} />
      }
    </ProfileWrapper>
  );
}

export default Profile;
