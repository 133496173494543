import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/admin/Sidebar';
import NavBar from '../../components/admin/NavBar';
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BASE_URL } from '../../services/BASE_URL';
import AdminServices from '../../services/AdminServices';

function ViewQRCodes() {
    const [qrCodeData, setQrCodeData] = useState([]);
    const [filteredQRCodes, setFilteredQRCodes] = useState([]);
    const [filterDate, setFilterDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [selectedQRCodes, setSelectedQRCodes] = useState(new Set());
    const [openDates, setOpenDates] = useState(new Set()); // State to manage open dates
    const fetchQrCodes = async () => {
        setLoading(true);
        try {
            const response = await AdminServices.GetAllQrCodes(currentPage);
            if (response) {
                setQrCodeData(response.data);
                setFilteredQRCodes(response.data);
                const pages = Math.floor(response.recordsTotal / 50) + 1;
                setTotalPages(pages);
            }
        } catch (error) {
            console.error('Error fetching QR codes:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        fetchQrCodes();
    }, [currentPage]);

    const handleFilterByDate = (date) => {
        setFilterDate(date);
        if (date) {
            const selectedDate = date.toISOString().split('T')[0];
            const nextDay = new Date(date);
            nextDay.setDate(nextDay.getDate() + 1);

            const filtered = qrCodeData.filter((qrCode) => {
                const createdAtDate = new Date(qrCode.createdAt).toISOString().split('T')[0];
                return createdAtDate >= selectedDate && createdAtDate < nextDay.toISOString().split('T')[0];
            });

            setFilteredQRCodes(filtered);
        } else {
            setFilteredQRCodes(qrCodeData);
        }
    };

    const handleSelectQRCode = (uuid) => {
        const newSelection = new Set(selectedQRCodes);
        if (newSelection.has(uuid)) {
            newSelection.delete(uuid);
        } else {
            newSelection.add(uuid);
        }
        setSelectedQRCodes(newSelection);
    };

    const downloadQRCode = (url, uuid) => {
        saveAs(`${BASE_URL}${url}`, `${uuid}.png`);
    };

    const downloadSelectedQRCodes = () => {
        selectedQRCodes.forEach((uuid) => {
            const qrCode = filteredQRCodes.find(qr => qr.uniqueIdentifier === uuid);
            if (qrCode) {
                downloadQRCode(qrCode.imagePath, qrCode.uniqueIdentifier);
            }
        });
    };

    const downloadAllQRCodes = async () => {
        setLoading(true);
        await AdminServices.DownloadAllQrCodes(currentPage, filterDate);
        setLoading(false);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const groupQRCodesByDate = () => {
        const grouped = {};
        filteredQRCodes.forEach(qrCode => {
            const date = new Date(qrCode.createdAt).toLocaleDateString();
            if (!grouped[date]) {
                grouped[date] = [];
            }
            grouped[date].push(qrCode);
        });
        return grouped;
    };

    const groupedQRCodes = groupQRCodesByDate();

    const toggleDateGroup = (date) => {
        const newOpenDates = new Set(openDates);
        if (newOpenDates.has(date)) {
            newOpenDates.delete(date);
        } else {
            newOpenDates.add(date);
        }
        setOpenDates(newOpenDates);
    };

    const handleDeleteQRCode = async (qrCodeId) => {
        const isDeleted = await AdminServices.deleteQRCode(qrCodeId);
        if (isDeleted.success) {
            fetchQrCodes();
            setSuccessMsg("QR code deleted successfully")
        } else {
            setErrorMsg(isDeleted.error);
        }
    };

    const handleDeleteSelectedQRCodes = async () => {
        if (!selectedQRCodes.size) return;

        const uniqueIdentifiers = Array.from(selectedQRCodes);
        console.log(uniqueIdentifiers);
        try {
            setLoading(true);
            const response = await AdminServices.bulkDeleteQRCodes(uniqueIdentifiers); 
            if (response.success) {
                setSuccessMsg("Selected QR codes deleted successfully.");
                fetchQrCodes(); 
                setSelectedQRCodes(new Set()); 
            } else {
                setErrorMsg("Failed to delete selected QR codes. Please try again.");
            }
        } catch (error) {
            console.error("Error deleting QR codes:", error);
            setErrorMsg("An error occurred while deleting QR codes.");
        } finally {
            setLoading(false);
        }
    };
    const handleDownload = async () => {
        if (!selectedQRCodes.size) return;

        const uniqueIdentifiers = Array.from(selectedQRCodes);
        console.log(uniqueIdentifiers);
        try {
            setLoading(true);
            const response = await AdminServices.bulkDownloadQRCodes(uniqueIdentifiers); 
            if (response.success) {
                setSuccessMsg("Selected QR codes Downloaded successfully.");
                // fetchQrCodes(); 
                setSelectedQRCodes(new Set()); 
            } else {
                setErrorMsg("Failed to download selected QR codes. Please try again.");
            }
        } catch (error) {
            console.error("Error Downloading QR codes:", error);
            setErrorMsg("An error occurred while deleting QR codes.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='container-fluid px-0 bg-light vh-100'>
                <NavBar />
                <div className='p-4 mb-3' style={{ width: "100%", height: 'calc(100% - 100px)', overflowY: 'auto' }}>
                    <h2 className='mb-4'>View QR Codes</h2>
                    <p className='text-success'>{successMsg}</p>
                    <p className='text-danger'>{errorMsg}</p>

                    <div className='d-flex justify-content-end align-items-center mb-4'>
                        <div className='me-2'>
                            <button
                                className='btn btn-success rounded-pill shadow-lg px-4'
                                onClick={handleDownload}
                                disabled={loading || selectedQRCodes.size === 0}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-download me-2" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                </svg>
                                {loading ? '.......' : 'Download'}
                            </button>
                            <button
                                className='btn btn-danger rounded-pill ms-2 shadow-lg px-4'
                                onClick={handleDeleteSelectedQRCodes}
                                disabled={loading || selectedQRCodes.size === 0}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class=" me-1 bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                </svg>
                                {loading ? '......' : 'Delete'}
                            </button>
                        </div>
                        <DatePicker
                            selected={filterDate}
                            onChange={handleFilterByDate}
                            className="form-control w-100 shadow-sm border border-primary"
                            placeholderText="12, October, 2024"
                            dateFormat="dd, MMMM, yyyy"
                            popperClassName="react-datepicker-custom"
                        />
                    </div>

                    <table className='table table-hover shadow-sm rounded'>
                        <thead className='thead-light'>
                            <tr>
                                <th className='text-center'>Select</th>
                                <th className='text-center' style={{ width: "55%" }}>UUID</th>
                                <th className='text-center'>QR Code</th>
                                <th className='text-center'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupedQRCodes)
                                .sort((a, b) => new Date(b) - new Date(a))
                                .map(date => (
                                    <React.Fragment key={date}>
                                        <tr className='table-primary' onClick={() => toggleDateGroup(date)}>
                                            <td className='align-middle text-center'>
                                                <input
                                                    type="checkbox"
                                                    style={{ zIndex: 99 }}
                                                    checked={groupedQRCodes[date].every(qrCode => selectedQRCodes.has(qrCode.uniqueIdentifier))}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={(e) => {
                                                        const allSelected = groupedQRCodes[date].every(qrCode => selectedQRCodes.has(qrCode.uniqueIdentifier));
                                                        const newSelected = new Set(selectedQRCodes);

                                                        if (allSelected) {
                                                            groupedQRCodes[date].forEach(qrCode => newSelected.delete(qrCode.uniqueIdentifier));
                                                        } else {
                                                            groupedQRCodes[date].forEach(qrCode => newSelected.add(qrCode.uniqueIdentifier));
                                                        }

                                                        setSelectedQRCodes(newSelected);
                                                    }}
                                                />
                                            </td>

                                            <td colSpan="2" className='fw-bold text-center'>{date}</td>
                                            <td className='align-middle text-center fw-bold'>
                                                ({groupedQRCodes[date].length})
                                            </td>
                                        </tr>

                                        {openDates.has(date) && groupedQRCodes[date].map(qrCode => (
                                            <tr key={qrCode.uniqueIdentifier} className='text-center'>
                                                <td className='align-middle'>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedQRCodes.has(qrCode.uniqueIdentifier)}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            handleSelectQRCode(qrCode.uniqueIdentifier);
                                                        }}
                                                    />
                                                </td>
                                                <td className='align-middle'>{qrCode.uniqueIdentifier}</td>
                                                <td className='align-middle'>
                                                    <img
                                                        src={`${BASE_URL}${qrCode.imagePath}`}
                                                        alt="QR Code"
                                                        width="100"
                                                        className='img-thumbnail shadow-sm'
                                                    />
                                                </td>
                                                <td className='align-middle'>
                                                    <button
                                                        className='btn btn-outline-success rounded-pill shadow-sm'
                                                        onClick={() => downloadQRCode(qrCode.imagePath, qrCode.uniqueIdentifier)}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        className='ms-1 btn btn-outline-danger rounded-pill shadow-sm'
                                                        onClick={() => handleDeleteQRCode(qrCode.uniqueIdentifier)}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                        </tbody>

                    </table>

                    <div className="d-flex justify-content-between mt-3">
                        <button
                            className="rounded-5 btn btn-outline-primary"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 0 || loading}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage + 1} of {totalPages}</span>
                        <button
                            className="rounded-5 btn btn-outline-primary"
                            onClick={handleNextPage}
                            disabled={currentPage + 1 >= totalPages || loading}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewQRCodes;
