import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import useAuthServices from '../../services/AuthServices';
import { useNavigate } from 'react-router-dom';
import useTributesServices from '../../services/TributesServices';

const TributesContainer = styled.div`
    margin-top: 1rem;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
`;

const CommentsList = styled.div`
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 10px;
    scrollbar-width: none;
    .comment-item {
         display: flex;   
         border: 1px solid #458ff6;
         max-width: 100%;
         background-color: #FFFAFA;
         margin-bottom: 10px;
         border-radius: 10px;
         padding-inline: 10px;
         padding-block: 10px;
    }
    
    &::-webkit-scrollbar {
        display: none;
    }
`;

const CommentProfileImg = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 50%;
`;

const CommentText = styled.p`
    color: black;
    margin-bottom: 0px;
    &.small {
        font-size: 0.875rem;
        font-weight: bold;
    }
`;

const CommentInput = styled(Form.Control)`
    width: 100%;
    margin-bottom: 12px;
    color: #000000 !important;
    font-weight: 400 !important;
    letter-spacing: 1 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 10px !important;
    background-color: #f0f0f0 !important;
    &::placeholder {
        color: #000000 !important;
        font-weight: 400 !important;
        font-size: 14px !important;
    }
`;

const CommentSubmitButton = styled.button`
    padding-block: 6px !important; 
    padding-inline: 16px !important; 
    border: none !important;
    outline: none !important;
    position: relative !important;
    border-radius: 20px !important; 
    background: linear-gradient(to right, #458ff6, #008fb9) !important;
    z-index: 1;
    cursor: pointer;
    font-size: 13px !important; 

    &::before {
        content: "";
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        background-color: #FFFFFF;
        border-radius: 20px; 
        z-index: -1;
        cursor: pointer;
    }
`;



function Tributes({ tributes, user, medallionId }) {
    const [newComment, setNewComment] = useState('');
    const [newtributes, setNewTributes] = useState(tributes);
    const navigate = useNavigate();

    const handleCommentChange = (event) => {
        setNewComment(event.target.value);
    };

    const { createtribute } = useTributesServices();

    const handleTributesSubmit = async () => {
        if (newComment.trim()) {
            const data = {
                medallionId: medallionId,
                userId: user.id,
                text: newComment
            }
            await createtribute(data);
            // console.log(user.imagePath);
            const newCommentObject = {
                user: { displayName: user.displayName, imagePath: user.imagePath },
                text: newComment,
            };
            setNewTributes([...newtributes, newCommentObject]);
            setNewComment("");
        }
    };

    const { getToken } = useAuthServices();

    return (
        <TributesContainer>
            <CommentsList>
                {[...newtributes].reverse().map((tribute, index) => (
                    <div key={index} className='comment-item'>
                        <div className='pb-1'>
                            <CommentProfileImg src={tribute.user.imagePath} alt='s' />
                        </div>
                        <div className='ps-2'>
                            <CommentText className='small'>{tribute.user ? tribute.user.displayName : 'Unknown User'}</CommentText>
                            <CommentText>{tribute.text}</CommentText>
                        </div>
                    </div>
                ))}

            </CommentsList>
            <div className='comments-section'>
                <Form.Group className="w-100" controlId="about">
                    <CommentInput as="textarea" rows={3} placeholder="Add a comment..." value={newComment} onChange={handleCommentChange} />
                </Form.Group>
                <CommentSubmitButton className='text-black' onClick={getToken() ? handleTributesSubmit : () => {
                    navigate("/login")
                }}>Post Tribute</CommentSubmitButton>
            </div>
        </TributesContainer>
    )
}

export default Tributes;
