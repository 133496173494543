import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import useAuthServices from './AuthServices';

const API_URL = `${BASE_URL}/api/FileUpload`;

const useUploadFileServices = () => {
    const { getToken } = useAuthServices();

    const uploadFile = async (file) => {
        const token = getToken();

        if (!token) {
            console.error("Token is missing. User may not be authenticated.");
            return { status: false, message: "User is not authenticated." };
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(API_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token.access_token}`,
                },
            });

            return { status: true, data: response.data };
        } catch (error) {
            console.error('Error uploading file:', error.response?.data || error.message);
            return {
                status: false,
                message: error.response?.data?.message || "File upload failed.",
            };
        }
    };

    return {
        uploadFile,
    };
};

export default useUploadFileServices;
