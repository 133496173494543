import React from "react";
import styled from "styled-components"
import { calculateAge, formatDate } from "../../functions/CommonFunctions";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-block: 30px
`;

const DeceasedName = styled.h3`
    color: #000000
`;

const Age = styled.span`
    color: #919191;
    font-size: 12px
`;

const Date = styled.p`
    color: #919191;
    font-size: 14px
`;

const Description = styled.p`
    margin-top: 10px;
    margin-bottom: 20px;
    color: var(--black);
    font-size: 14px;
    text-align: justify   
`;


function AboutMe({ medallion }) {

    const age = (medallion.dateOfBirth && medallion.dateOfDeath) ? calculateAge(medallion.dateOfBirth, medallion.dateOfDeath) : null;

    const dob = medallion.dateOfBirth ? formatDate(medallion.dateOfBirth) : null;
    const dod = medallion.dateOfDeath ? formatDate(medallion.dateOfDeath) : null;

    const descriptionText = `${medallion.about}`.replace(/\n/g, '<br />');


    return (

        <Wrapper>
            <DeceasedName>
                {medallion.displayName}  {age !== null && <Age>({age} Years)</Age>}
            </DeceasedName>

            {dob && dod && <Date>({dob} - {dod})</Date>}
            <Description dangerouslySetInnerHTML={{ __html: descriptionText }} />
        </Wrapper>
    )
}

export default AboutMe