import React from 'react'
import logo from '../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'

function Header() {
  const navigate = useNavigate();
  return (
    <div>
      <div className='d-flex align-items-center'>
        <button onClick={() => navigate(-1)} className='back-button me-2'>
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 19L8 12L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <img src={logo} alt="Heaven's Code" className='logo' />
        <p className='text-black ms-2 mb-0 title'>Heaven's Code</p>
      </div>
    </div>
  )
}

export default Header