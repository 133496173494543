
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { BASE_URL } from './BASE_URL';

const API_URL = `${BASE_URL}/api/Accounts/`;

const useAuthServices = () => {
    const { token, saveToken, logout } = useAuth();

    const register = async (displayName, email, password, imagePath) => {
        const data = imagePath ? { displayName, email, password, imagePath } : { displayName, email, password };
        try {
            await axios.post(`${API_URL}SignUp`, data);
            return { success: true };
        } catch (error) {
            console.error("Error during registration:", error);
            const errorMsg = error.response ? error.response.data : 'Unexpected Error occurred during Registration';
            const statusMessage = typeof errorMsg === 'object' && errorMsg.statusMessage
                ? errorMsg.statusMessage
                : errorMsg;
            console.log(statusMessage);
            return { success: false, error: statusMessage };
        }
    };

    const forgetPassword = async (email) => {
        try {
            const response = await axios.post(`${API_URL}ForgetPassword`, { email }, {
                headers: {
                    'Content-Type': 'application/json-patch+json',
                },
            });

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error during forget password:", error);
            const errorMsg = error.response ? error.response.data : "Failed to send password reset link. Please try again.";
            return { success: false, error: errorMsg };
        }
    };

    const resetPassword = async (email, newPassword, confirmPassword, key) => {
        try {
            const response = await axios.post(`${API_URL}ResetPassword`, {
                email,
                newPassword,
                confirmPassword,
                key
            }, {
                headers: {
                    'Content-Type': 'application/json-patch+json',
                },
            });
    
            // toast.success('Password has been reset successfully.');
            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error during password reset:", error);
            const errorMsg = error.response ? error.response.data : 'Failed to reset password. Please try again.';
            // toast.error(errorMsg);
            return { success: false, error: errorMsg };
        }
    };

    const socialSignin = async (userName, email, picture) => {
        const password = "987654321";
        try {
            const response = await axios.post(`${API_URL}SocialSignIn`, {
                userName,
                email,
                password,
                picture,
            });

            if (response.status === 200) {
                saveToken(response.data);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error during social sign-in:", error);
            const errorMsg = error.response ? error.response.data : 'Unexpected Error occured during social signin';
            console.error("Error during social sign-in:", errorMsg);
            // toast.error(errorMsg);
            return false;
        }
    };

    const login = async (email, password) => {
        try {
            const response = await axios.post(`${API_URL}Login`, { email, password });
            if (response.data.access_token) {
                saveToken(response.data);
                return { success: true };
            }
        } catch (error) {
            console.error("Error during login:", error);
            const errorMsg = error.response ? error.response.data : 'Unexpected Error occured during Login';
            // toast.error(errorMsg);
            return { success: false, error: errorMsg };
        }
    };

    const getToken = () => {
        // console.log(localStorage.getItem('user'));
        return token || localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    };


    const getUserDetails = async (navigate) => {
        const token = getToken();

        if (!token) {
            logout();
            navigate("/login");
            return;
        }

        try {
            const response = await axios.get(`${API_URL}GetLoginUserDetail`, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                },
            });

            return response;
        } catch (error) {
            console.error("Error fetching user details:", error);
            logout();
            navigate("/login");
            setTimeout(() => {
                // toast.error("Session Expired. Login again");
            }, 300);
        }
    };

    const isCurrentUser = async (id, navigate) => {
        try {
            const response = await getUserDetails(navigate);
            return response?.data?.id === id;
        } catch (error) {
            console.error("Error fetching current user:", error);
            return false;
        }
    };

    const updateProfile = async (id, profileData) => {
        const token = getToken();
        if (!token) {
            logout();
            return;
        }

        try {
            const response = await axios.post(`${API_URL}UpdateProfile`, { ...profileData, id }, {
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    Authorization: `Bearer ${token.access_token}`,
                },
            });

            if (response.status === 200) {
                // toast.success('Profile updated successfully.');
            }
            return response.data;
        } catch (error) {
            const errorMsg = error.response ? error.response.data : 'Unexpected error occured when updating profile';
return false;
            // toast.error(errorMsg);
        }
    };

    const updatePassword = async (userId, oldPassword, newPassword, confirmPassword) => {
        const token = getToken();
        try {
            const response = await axios.post(`${API_URL}UpdatePassword`, {
                userId,
                oldPassword,
                newPassword,
                confirmPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json-patch+json',
                },
            });
            return { success: true, data: response.data };
        } catch (error) {
            const errorMsg = error.response ? error.response.data : 'Error updating password';
            return { success: false, error: errorMsg }
        }
    };

    return {
        register,
        socialSignin,
        login,
        logout,
        getToken,
        forgetPassword,
        getUserDetails,
        isCurrentUser,
        updateProfile,
        updatePassword,
        resetPassword
    };
};

export default useAuthServices;
