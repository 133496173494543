import React, { useState } from 'react';
import Header from '../components/Header';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import useAuthServices from '../services/AuthServices';
import LoadingIndicator from '../components/LoadingIndicator';

function ForgetPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const { forgetPassword } = useAuthServices();

  const handleForgetPassword = async () => {
    const response = await forgetPassword(email);
    if (response.success) {
      console.log('Success:', response.data);
      setSuccess("A password reset link has been sent to your email.");
    } else {
      console.error('Error:', response.error);
      setError(response.error);
    }
  };


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setSuccess('');
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email) {
      setError('Email is required.');
      setLoading(false)
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Invalid email address.');
      setLoading(false)
      return;
    }

    // setSuccess("Email Sent Successfully");
    await handleForgetPassword();

    setLoading(false);

    // console.log("Email: ", email);
    // navigate('/login');
  };

  return (
    <div className='main_container' style={{ minHeight: "100vh" }}>
      <div className='screen_container justify-content-between'>
        <Header />
        <div>
          <p className='text-black text-center my-2'>Forget Password</p>
          {success && <p className='text-success text-center mt-1' style={{ fontSize: '14px' }}>{success}</p>}
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1'>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  className='input'
                  value={email}
                  onChange={handleEmailChange}
                />
                {error && <p className='text-danger ms-1 mt-1' style={{ fontSize: '14px' }}>{error}</p>}
              </Form.Group>
              {loading ? (
                <LoadingIndicator />
              ) : (
                <Button className="w-100 actionBtn mb-1" type="submit">
                  Send Link
                </Button>
              )}
            </Form>
          </div>
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1 mt-3'>
            <p className='text-black text-center mb-2'>Back to Login?</p>
            <Button className='w-100 signupbtn mt-2 text-black' id="signupbtn" type="button" onClick={() => navigate('/login')}>
              Sign in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
