import React, { useState, useEffect, useRef, useCallback } from 'react';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Button, Modal } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import Loader from '../components/Loader';
import UploadFileServices from '../services/UploadFileServices';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../services/BASE_URL';
import { getCroppedImg } from '../utils/CropImage';
import edit from '../assets/icons/pen-24.png';
import profile from '../assets/images/person.jpeg';
import useAuthServices from '../services/AuthServices';
import useUploadFileServices from '../services/UploadFileServices';

function EditOwnerProfile() {
  const [user, setUser] = useState(null);
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwords, setPasswords] = useState({
    previousPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [cropper, setCropper] = useState({
    showCropper: false,
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: null,
    croppedImageFile: null
  });
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const { getUserDetails, updateProfile, updatePassword } = useAuthServices();

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const response = await getUserDetails(navigate);
        const userData = response.data;
        setUser(userData);
        setFormData({
          name: userData.displayName || '',
          email: userData.email || '',
          password: ''
        });
        setProfileImage(userData.imagePath);
      } catch (error) {
        setError('Something went wrong');
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [navigate, id]);

  const validateFields = () => {
    setError('');
    setSuccess('');
    if (!formData.name) {
      setError('Name is required.');
      return false;
    }
    if (formData.password && formData.password.length < 6) {
      setError('Password should be at least 6 characters long.');
      return false;
    }
    return true;
  };
  const { uploadFile } = useUploadFileServices();

  const updateUserProfile = async () => {
    setLoading(true);
    if (!validateFields()) {
      setLoading(false);
      return;
    }


    try {
      let profilepic;
      let path = user.imagePath;


      if (cropper.croppedImageFile) {
        profilepic = await uploadFile(cropper.croppedImageFile);
        path = `${BASE_URL}${profilepic?.data?.url || path}`;
      }

      const updatedData = {
        displayName: formData.name,
        email: formData.email,
        imagePath: `${path}`,
        ...(formData.password && { password: formData.password }),
      };

      const response = await updateProfile(id, updatedData);

      if (response.statusCode === 200) {
        navigate(-1);
      } else {
        setError('Unexpected Error Occured! Failed to update profile.');
      }
    } catch (error) {
      setError('Unexpected Error Occured! Failed to update profile.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const cancelImage = () => {
    setCropper(prev => ({
      ...prev,
      showCropper: false,
      croppedImageFile: null
    }));
    setProfileImage(user.imagePath);
  };

  const handlePasswordChange = async () => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }
    if (passwords.previousPassword === '' || passwords.newPassword === '') {
      setError('Please fill in all the fields.');
      return;
    }
    setLoading(true);
    try {
      const response = await updatePassword(id, passwords.previousPassword, passwords.newPassword, passwords.confirmPassword);
      if (response.success) {
        setSuccess('Password changed successfully.');
      } else {
        const errorMessage =
          typeof response.error === 'object'
            ? response.error.statusMessage || 'Unexpected error occurred.'
            : response.error || 'Failed to reset password. Please try again.';
        setError(errorMessage);
        setPasswords([]);
      }
    } catch (error) {
      setError('Error Occured while changing password.');
    } finally {
      setLoading(false);
      setShowPasswordModal(false);
    }
  };


  const onCropComplete = useCallback(async () => {
    try {
      const { file, url } = await getCroppedImg(profileImage, cropper.croppedAreaPixels);

      const uniqueId = Date.now();
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `${formData.name}-${uniqueId}.${fileExtension}`;
      const renamedFile = new File([file], uniqueFileName, { type: file.type });

      setProfileImage(url);
      setCropper(prev => ({
        ...prev,
        croppedImageFile: renamedFile,
        showCropper: false
      }));
    } catch (error) {
      console.error(error);
    }
  }, [cropper.croppedAreaPixels, profileImage, formData.name]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
      setCropper(prev => ({
        ...prev,
        showCropper: true
      }));
    }
  };

  return (
    <div>
      <div className='main_container h-auto' style={{ minHeight: "80vh" }}>
        <div
          className={`screen_container d-flex align-items-center ${loading && "justify-content-center"}`}
          style={{ height: loading ? '80vh' : 'auto' }}
        >
          <ToastContainer toastClassName='toaster' />
          {loading ? (
            <Loader />
          ) : (
            <div className='w-100'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='w-50'>
                  <Header />
                </div>
                <Button variant="outline-danger rounded-5 d-block" onClick={() => setShowPasswordModal(true)}>
                  Change Password
                </Button>
              </div>
              <div className='d-flex flex-column align-items-center mt-3'>
                <p className='text-black text-center fs-3 w-50 mt-3 mb-5'>
                  Edit Your Profile
                </p>
                {error && <p className='text-danger text-center' style={{ fontSize: '12px' }}>{error}</p>}
                {success && <p className='text-success text-center' style={{ fontSize: '12px' }}>{success}</p>}
                <div className='profile-pic-container position-relative'>
                  <img
                    src={profileImage || profile}
                    alt='Profile'
                    className='rounded-circle profile-pic'
                  />
                  <img
                    src={edit}
                    alt='edit'
                    onClick={() => fileInputRef.current.click()}
                    className='edit-icon position-absolute'
                  />
                </div>
                <Form className='single-form d-flex flex-column justify-content-center align-items-center w-100 mt-3'>
                  <Form.Group className='mb-3 w-100' controlId='name'>
                    <Form.Control
                      type='text'
                      placeholder='Name'
                      className='shopnow-input'
                      value={formData.name}
                      onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3 w-100' controlId='email'>
                    <Form.Control
                      type='email'
                      placeholder='Email'
                      className='shopnow-input'
                      value={formData.email}
                      onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3 w-100' controlId='imagePath'>
                    <Form.Control
                      type='file'
                      placeholder='Image Path'
                      className='file-input'
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                  </Form.Group>
                  <Button
                    className='paybtn my-2 w-50 w-md-25 w-lg-25 w-sm-25 w-xl-25'
                    id='paybtn'
                    type='button'
                    onClick={updateUserProfile}
                  >
                    Update Profile
                  </Button>
                </Form>
              </div>
            </div>
          )}
        </div>

        <Modal show={cropper.showCropper} onHide={() => setCropper(prev => ({ ...prev, showCropper: false }))} centered>
          <Modal.Header closeButton>
            <Modal.Title>Crop Your Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='crop-container'>
              <Cropper
                image={profileImage}
                crop={cropper.crop}
                zoom={cropper.zoom}
                aspect={1}
                onCropChange={(crop) => setCropper(prev => ({ ...prev, crop }))}
                onZoomChange={(zoom) => setCropper(prev => ({ ...prev, zoom }))}
                onCropComplete={(croppedArea, croppedAreaPixels) => setCropper(prev => ({ ...prev, croppedAreaPixels }))}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='rounded-5' onClick={cancelImage}>
              Cancel
            </Button>
            <Button variant='primary' className='rounded-5 px-3' onClick={onCropComplete}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          {error && <p className='text-danger text-center' style={{ fontSize: '12px' }}>{error}</p>}
          {success && <p className='text-success text-center' style={{ fontSize: '12px' }}>{success}</p>}
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3'>
                <Form.Control
                  type='password'
                  placeholder='Current Password'
                  value={passwords.previousPassword}
                  onChange={(e) => setPasswords(prev => ({ ...prev, previousPassword: e.target.value }))}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Control
                  type='password'
                  placeholder='New Password'
                  value={passwords.newPassword}
                  onChange={(e) => setPasswords(prev => ({ ...prev, newPassword: e.target.value }))}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Control
                  type='password'
                  placeholder='Confirm New Password'
                  value={passwords.confirmPassword}
                  onChange={(e) => setPasswords(prev => ({ ...prev, confirmPassword: e.target.value }))}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='rounded-5' onClick={() => setShowPasswordModal(false)}>Cancel</Button>
            <Button variant='primary' className='rounded-5' onClick={handlePasswordChange}>Change Password</Button>
          </Modal.Footer>
        </Modal>
      </div>

    </div>
  );
}

export default EditOwnerProfile;
