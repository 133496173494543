import React, { useState } from 'react';
import Header from '../../components/Header';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoadingIndicator from '../../components/LoadingIndicator';
import AdminServices from '../../services/AdminServices';

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();


  const handleEmailChange = (e) => {

    setSuccess('')
    setError('')
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {

    setSuccess('')
    setError('')
    setPassword(e.target.value);
  };

  const onSignin = async () => {
    setLoading(true);
    try {
      if (email.trim() === "" || password.trim() === "") {
        setError("Please fill in the required fields!");
        return;
      }

      const response = await AdminServices.loginAdmin(email, password);
      // console.log(response);
      if (response.success) {
        setTimeout(
          () => {
            setSuccess("You have logged in successfully!");
            navigate('/admin/dashboard');
          }, 1000
        )
      } else {
        const errorMessage =
          typeof response.error === 'object'
            ? response.error.statusMessage || 'Unexpected error occurred.'
            : response.error || 'Try Login Again..';
        setError(errorMessage);
      }
    } catch (error) {
      console.error("There was an error!", error);
      const errorMessage = error.response?.data || error.response?.data?.statusMessage || "Login failed!";
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='main_container' style={{ minHeight: "100vh" }}>
      <div className='screen_container justify-content-between' style={{ minHeight: "80vh" }}>
        <Header />
        <ToastContainer toastClassName='toaster' />
        <div>
          <p className='text-black text-center my-2'>Log in</p>
          {error && <p className='text-danger text-center' style={{ fontSize: '12px' }}>{error}</p>}
          {success && <p className='text-success text-center' style={{ fontSize: '12px' }}>{success}</p>}
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1'>
            <Form onSubmit={(e) => { e.preventDefault(); onSignin(); }}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  className='input'
                  value={email}
                  onChange={handleEmailChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <InputGroup>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    className='input'
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <Button
                    className='input'
                    style={{ width: "15%" }}
                    id="forgetButton"
                    type="button"
                    onClick={() => navigate('/forget-password')}
                  >
                    <p className='forgetButton'>Forget?</p>
                  </Button>
                </InputGroup>
              </Form.Group>
              <div>
                {loading ? (
                  <LoadingIndicator />
                ) : (
                  <Button
                    className="w-100 actionBtn mb-1"
                    type="submit"
                  >
                    Sign in
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
