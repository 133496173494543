import React, { useState } from 'react';
import Sidebar from '../../components/admin/Sidebar';
import NavBar from '../../components/admin/NavBar';
import AdminServices from '../../services/AdminServices';
import { toast } from 'react-toastify';

function CreateQRCodes() {
    const [numOfQRCodes, setNumOfQRCodes] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCreateQRCodes = async () => {
        // console.log(`Creating ${numOfQRCodes} QR codes`);
        setLoading(true);
        if (numOfQRCodes < 1) {
            setError("QR CODE Count should be greater than 1");
            setLoading(false);

            return;
        }
        const res = await AdminServices.createQRCodes(numOfQRCodes);
        if (res.statusCode === 200) {
            setSuccess("QR Codes created successfully");
            setNumOfQRCodes('');
            setLoading(false);

        } else {
            setError("UnExpected Error Occured. Try Again");
            setLoading(false);

        }
    };

    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='container-fluid px-0 bg-light vh-100'>
                <NavBar />
                <div className='p-3'>
                    <h2>Create QR Codes</h2>
                    {error && <p className='text-danger text-center' style={{ fontSize: '14px' }}>{error}</p>}
                    {success && <p className='text-success text-center' style={{ fontSize: '14px' }}>{success}</p>}
                    <div className='mt-4 w-50 mx-auto d-flex flex-column align-items-center'>
                        <input
                            type='number'
                            className='form-control'
                            placeholder='Enter number of QR codes'
                            value={numOfQRCodes}
                            onChange={(e) => {
                                setError('')
                                setSuccess('')
                                setNumOfQRCodes(e.target.value)
                            }}
                        />
                        {
                            loading ? <button className='btn btn-primary rounded-5 px-3 py-2 my-4' disabled>Creating...</button> : <button
                                className='btn btn-primary rounded-5 px-3 py-2 my-4'
                                onClick={handleCreateQRCodes}
                            >
                                Create QR Codes
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateQRCodes;
