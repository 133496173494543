import React from 'react';
import PropTypes from 'prop-types';
import defaultProfileImage from '../assets/images/person.jpeg';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../functions/CommonFunctions';
import useNotificationServices from '../services/NotificationsServices';

const ProfileRequestNotification = ({ notification, user }) => {
  const navigate = useNavigate();
  const {updateReadAt} = useNotificationServices();
  return (
    <div className="notification-container d-flex align-items-center p-3 mb-2" style={{cursor: "pointer"}} onClick={async () => {
      // console.log(notification.id, user);
      try {
        await updateReadAt(notification.id);
        if (notification.medallionRequestStatus) {
          navigate(`/d-profile/${notification.medallionId}`)
        } else if (notification.medallionRequestStatus == null) {
          navigate('/requests', { state: { user } });
        } else {
          navigate(`/declined-request`)
        }
      } catch (e) {
        console.error("ERROR");
      }
    }}>
      <img
        src={notification.fromUser.imagePath || defaultProfileImage}
        alt="requester profile"
        className="requester-profile-image me-3"
      />
      <div className="notification-content">
        <p className="mb-1" style={{ color: notification.readAt ? "#4f5052" : "black", fontWeight: notification.readAt ? "normal" : "bold" }}>
          {notification.message}
        </p>
        <small className="small" style={{ color: notification.readAt ? "#4f5052" : "black", fontWeight: notification.readAt ? "normal" : "bold" }}>{formatDateTime(notification.createdAt)}</small>
      </div>
    </div>
  );
};

ProfileRequestNotification.propTypes = {
  requesterName: PropTypes.string.isRequired,
  requesterProfileImage: PropTypes.string,
  requestTime: PropTypes.string.isRequired,
};

export default ProfileRequestNotification;
