import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/admin/Sidebar'
import NavBar from '../../components/admin/NavBar'
import WelcomeAlert from '../../components/admin/WelcomeAlert'
import AdminServices from '../../services/AdminServices';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import CountCards from '../../components/admin/CountCards';

function Dashboard() {

    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDashboardCounts = async () => {
            try {
                const response = await AdminServices.getDashboardCounts();
                const counts = response.data || [];
                setUserData([
                    {
                        title: "Total Users",
                        count: counts.userCount || 0,
                        icon: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                        </svg>
                    },
                    {
                        title: "Memorial Profiles",
                        count: counts.medallionCount || 0,
                        icon: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-square" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                        </svg>
                    },
                    {
                        title: "QR Codes",
                        count: counts.qrCodeCount || 0,
                        icon: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-qr-code" viewBox="0 0 16 16">
                            <path d="M2 2h2v2H2z" />
                            <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z" />
                            <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z" />
                            <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z" />
                            <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z" />
                        </svg>
                    },
                ]);
            } catch (error) {
                console.error("Error fetching dashboard counts:", error);
                // toast.error("Failed to load dashboard data.");
            }
        };
    
        fetchDashboardCounts();
    }, [navigate]);
    

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const admin = await AdminServices.getAdminDetails(navigate);
                // console.log('Admin Details:', admin);
                setUser(admin?.data);
            } catch (error) {
                console.error("Error fetching user details:", error);
                // toast.error("Unexpected Error Occurred.");
            } finally {
                setLoading(false);
            }
        };
        fetchUserDetails();
    }, [navigate]);

    // const userData = [
    //     {
    //         title: "Total Users", count: 120, icon: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
    //             <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
    //         </svg>
    //     },
    //     {
    //         title: "Memorial Profiles", count: 85, icon: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-square" viewBox="0 0 16 16">
    //             <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
    //             <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
    //         </svg>
    //     },
    //     {
    //         title: "QR Codes", count: 35, icon: <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-qr-code" viewBox="0 0 16 16">
    //             <path d="M2 2h2v2H2z" />
    //             <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z" />
    //             <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z" />
    //             <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z" />
    //             <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z" />
    //         </svg>
    //     },
    // ];


    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='container-fluid px-0 bg-light vh-100'>
                <NavBar />
                {
                    loading ? <div className='d-flex align-items-center justify-content-center' style={{ minHeight: "80vh" }}>
                        <Loader />
                    </div> : <div>
                        <CountCards data={userData} />
                    </div>
                }
            </div>
        </div>
    )
}

export default Dashboard