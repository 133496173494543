import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import useAuthServices from './AuthServices';

const API_URL = `${BASE_URL}/api/QRCode`;

const useQRCodeServices = () => {
    const { getToken } = useAuthServices();
    const token = getToken();

    const getQRCodes = async () => {
        try {
            const response = await axios.get(
                `${API_URL}/GetLoginUserQrCodes`,
                {
                    headers: {
                        Authorization: `Bearer ${token.access_token}`
                    },
                }
            );
            return response;
        } catch (error) {
            console.error('Error getting QR codes:', error);
            return false;
        }
    };

    const getQrCodeByGuId = async (id) => {
        try {
            const response = await axios.get(`${API_URL}/GeQrCodeByGuId?Id=${id}`, {
                headers: {
                    // Authorization: `Bearer ${token.access_token}`, 
                    'accept': '*/*'
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error getting QR code by GuId:', error);
            return false;
        }
    };

    return {
        getQRCodes,
        getQrCodeByGuId
    };
};

export default useQRCodeServices;
