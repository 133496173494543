export function formatDateTime(isoDateStr) {
    const dateObj = new Date(isoDateStr);

    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const dateOptions = { month: 'long', day: 'numeric', year: 'numeric' };

    const time = dateObj.toLocaleTimeString('en-US', timeOptions);
    const date = dateObj.toLocaleDateString('en-US', dateOptions);

    return `${time}, ${date}`;
}

export function calculateAge(dateOfBirth, dateOfDeath) {
    const birthDate = new Date(dateOfBirth);
    const deathDate = new Date(dateOfDeath);

    let age = deathDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = deathDate.getMonth() - birthDate.getMonth();
    const dayDiff = deathDate.getDate() - birthDate.getDate();

    // Adjust age if the death date is before the birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
}


export function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    const [day, month, year] = formattedDate.split(' ');
    const dayWithSuffix = `${day}${getDaySuffix(day)}`;
    return `${dayWithSuffix} ${month}, ${year}`;
}

function getDaySuffix(day) {
    const dayNumber = parseInt(day, 10);
    if (dayNumber >= 11 && dayNumber <= 13) return 'th';
    switch (dayNumber % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}
