import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/admin/Sidebar';
import NavBar from '../../components/admin/NavBar';
import AdminServices from '../../services/AdminServices';
import { BASE_URL } from '../../services/BASE_URL';
import profile from '../../assets/images/person.jpeg';


function QRMemorialProfiles() {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMemorialProfiles = async () => {
            setLoading(true);
            const profiles = await AdminServices.GetALLMemorialProfiles(pageNumber);

            if (profiles && profiles.data) {
                const formattedUsers = profiles.data.map((profile, index) => ({
                    id: index + 1,
                    username: profile.displayName,
                    createdBy: profile.user.displayName,
                    picture: profile.imagePath,
                    qrcode: profile.qrCodeId
                }));
                setUsers(formattedUsers);
                const pages = Math.floor(profiles.recordsTotal / 50) + 1;
                setTotalPages(pages);
            }
            setLoading(false);
        };

        fetchMemorialProfiles();
    }, [pageNumber]);

    const handleRedirectToUser = (username) => {
        navigate(`/admin/users?search=${username}`);
    };

    const handlePreviousPage = () => {
        if (pageNumber > 1) setPageNumber(pageNumber - 1);
    };

    const handleNextPage = () => {
        if (pageNumber < totalPages) setPageNumber(pageNumber + 1);
    };

    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.createdBy.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='container-fluid px-0 bg-light vh-100'>
                <NavBar />
                <div className='p-4 mb-3' style={{ width: "100%", height: 'calc(100% - 100px)', overflowY: 'auto' }}>
                    <h2 className='mb-4'>Memorial Profiles</h2>
                    <div className='mb-4 row justify-content-end'>
                        <div className='col-6 col-lg-4 col-xl-4 col-md-4'>
                            <input
                                type="text"
                                placeholder="Search users by name or email"
                                className="form-control w-100"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                    <table className='table table-hover shadow-sm rounded'>
                        <thead className='thead-light'>
                            <tr>
                                <th className='text-center'>Picture</th>
                                <th className='text-center'>Name</th>
                                <th className='text-center'>Created By</th>
                                <th className='text-center'>QR Code</th>
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? ( 
                                <tr>
                                    <td colSpan="5" className='text-center'>
                                        Loading data...
                                    </td>
                                </tr>
                            ) : filteredUsers.length > 0 ? (
                                filteredUsers.map(user => (
                                    <tr key={user.id} className='text-center'>
                                        <td className='align-middle'>
                                            <img
                                                src={user.picture ? `${BASE_URL}/${user.picture}` : profile}
                                                alt={`${user.username}'s Profile`}
                                                width="50"
                                                className='img-thumbnail rounded-circle'
                                            />
                                        </td>
                                        <td className='align-middle'>{user.username}</td>
                                        <td className='align-middle'>
                                            <span
                                                className='text-primary'
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleRedirectToUser(user.createdBy)}
                                            >
                                                {user.createdBy}
                                            </span>
                                        </td>
                                        <td className='align-middle'>
                                            <img
                                                src={`${BASE_URL}/QRcode/${user.qrcode}.png`}
                                                alt="QR Code"
                                                width="100"
                                                className='img-thumbnail shadow-sm'
                                            />
                                        </td>
                                        <td className='align-middle'>
                                            <button
                                                className='btn btn-outline-danger rounded-pill'
                                                onClick={() => {
                                                    window.open(`https://heavenscode.io/d-profile/${user.id}`);
                                                }}
                                            >
                                                Visit Profile
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className='text-center'>No users found.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-between">
                        <button
                            className="btn btn-outline-primary rounded-5"
                            onClick={handlePreviousPage}
                            disabled={pageNumber === 0}
                        >
                            Previous
                        </button>
                        <span>Page {pageNumber + 1} of {totalPages}</span>
                        <button
                            className="btn btn-outline-primary rounded-5"
                            onClick={handleNextPage}
                            disabled={pageNumber + 1 === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QRMemorialProfiles;
