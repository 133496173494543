import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import useAuthServices from './AuthServices';

const API_URL = `${BASE_URL}/api/Tributes`;

const useTributesServices = () => {
    const { getToken } = useAuthServices();
    const token = getToken();


    const createtribute = async (data) => {
        try {
            const response = await axios.post(
                `${API_URL}/Create`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token.access_token}`
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error creating tribute:', error);
            return false;
        }
    }

    const getTributesByID = async (id) => {
        try {
            const response = await axios.get(
                `${API_URL}/GetTributesOfMedallion?MedallionId=${id}`,
                {
                    headers: {
                        // Authorization: `Bearer ${token.access_token}`
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error getting tributes:', error);
            return false;
        }
    };


    return {
        getTributesByID,
        createtribute
    };
};

export default useTributesServices;

