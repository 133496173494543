import React from 'react'
import logo from '../assets/images/logo.png';


function Loader() {
    return (
        <div className='circle'>
            <div className='loader'></div>
            <img src={logo} alt="Heaven's Code" className='splashLogo' />
        </div>
    )
}

export default Loader