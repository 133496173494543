import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import Profile from '../components/profile/Profile';
import useStoriesServices from '../services/StoriesServices';
import useTributesServices from '../services/TributesServices';
import useMedallionServices from '../services/MedallionServices';
import useAuthServices from '../services/AuthServices';
import useRequestServices from '../services/RequestServices';

function DeceasedProfileScreen() {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const [stories, setStories] = useState([]);
    const [tributes, setTributes] = useState([]);
    const [medallion, setMedallion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isUser, setIsUser] = useState(false);
    const [user, setUser] = useState(null);
    const [isPrivate, setIsPrivate] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const { getMedallionByID, getByID } = useMedallionServices();
    const { getUserDetails, isCurrentUser, getToken } = useAuthServices();

    useEffect(() => {
        const fetchMedallion = async () => {
            try {
                let medallionData;
                // console.log(location.state?.medallion);
                if (location.state?.medallion) {
                    medallionData = location.state.medallion;
                    const userResponse = await getUserDetails(navigate);
                    // console.log(userResponse);
                    setUser(userResponse.data);
                    const isLoginUser = await isCurrentUser(medallionData.createdBy, navigate);
                    setIsUser(isLoginUser);
                } else {
                    let response;
                    const token = await getToken();
                    // console.log("Token: ", token);

                    if (token) {
                        const userResponse = await getUserDetails(navigate);
                        setUser(userResponse.data);
                        response = await getMedallionByID(id);
                        if (response.statusCode === 403) {
                            setIsPrivate(true);
                            return;
                        }
                    } else {
                        response = await getByID(id);
                    }

                    if (response.data) {
                        medallionData = response.data;

                        console.log(medallionData);
                        if (medallionData.isPrivate) {
                            if (!token) {
                                setIsPrivate(true);
                                return;
                            } else {
                                response = await getMedallionByID(id);
                                if (response.statusCode === 403) {
                                    setIsPrivate(true);
                                    return;
                                }
                            }
                        }
                        if (token) {
                            const isLoginUser = await isCurrentUser(medallionData.createdBy, navigate);
                            setIsUser(isLoginUser);
                        }
                    } else {
                        throw new Error('Medallion not found');
                    }
                }

                setMedallion(medallionData);
            } catch (error) {
                console.error('An error occurred while fetching the data.');
                // navigate(-1);
            } finally {
                setLoading(false);
            }
        };

        fetchMedallion();
    }, [id, location.state, navigate]);
    const { getStoriesByID } = useStoriesServices();
    const { getTributesByID } = useTributesServices();
    useEffect(() => {
        if (!medallion) return;

        const fetchDetails = async () => {
            try {
                const [storiesResponse, tributesResponse] = await Promise.all([
                    getStoriesByID(medallion.id),
                    getTributesByID(medallion.id)
                ]);

                setStories(storiesResponse || []);
                setTributes(tributesResponse || []);
            } catch (error) {
                console.error('Failed to fetch stories or tributes.');
            }
        };

        fetchDetails();
    }, [medallion]);

    const { requestViewMedallion } = useRequestServices();

    const handleRequestAccess = async () => {
        setSuccess('');
        setError('');
        if (!user) {
            setError('You need to be logged in first to send a request.');
            return;
        }

        setLoading(true);
        try {
            const res = await requestViewMedallion({
                medallionId: id,
                requestingUserId: user.id
            });
            if (res.statusCode === 200) {
                setSuccess('Request sent successfully.');
            } else {
                setError('Failed to send request.');
            }
        } catch (error) {
            setError('An error occurred while sending the request.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className={`screen_container d-flex align-items-center ${loading && "justify-content-center"}`} style={{ height: loading ? "84.2vh" : "auto", minHeight: "84.2vh" }}>
                <ToastContainer toastClassName='toaster' />
                {loading ? (
                    <Loader />
                ) : (
                    <div style={{ height: isPrivate ? "60vh" : 'auto', width: "100%" }}>
                        <div className='d-flex justify-content-between align-items-center pb-3'>
                            <Header />
                        </div>
                        {error && <p className='text-danger text-center' style={{ fontSize: '12px' }}>{error}</p>}
                        {success && <p className='text-success text-center' style={{ fontSize: '12px' }}>{success}</p>}
                        {isPrivate ? (
                            <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
                                <h4 className='text-black fw-2 text-center'>Hi, {user?.displayName}</h4>
                                <p className='text-black text-center'>
                                    This profile is private and cannot be viewed without permission. If you'd like to access this profile, please send a request to the owner for viewing rights.
                                </p>
                                <div className='profile-btn h-auto py-2 w-50' onClick={handleRequestAccess}>
                                    <p className='text-black mb-0 text-center'>Send Request</p>
                                </div>
                            </div>
                        ) : (
                            <Profile medallion={medallion} stories={stories} isUser={isUser} tributes={tributes} user={user} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default DeceasedProfileScreen;
