import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import facebook from '../assets/icons/facebook.png'
import google from '../assets/icons/google.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuthServices from '../services/AuthServices';
import LoadingIndicator from '../components/LoadingIndicator';
import profile from '../assets/images/person.jpeg';
import UploadFileServices from '../services/UploadFileServices';
import useSocialAuth from '../functions/SocialAuth';
import useUploadFileServices from '../services/UploadFileServices';


function Signup() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [loading, setLaoding] = useState(false);
  const [id, setId] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { register } = useAuthServices();
  const { FBlogin, googleLogin } = useSocialAuth();
  const { uploadFile } = useUploadFileServices();
  const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const idParam = params.get('uuid');
    if (idParam) {
      // console.log(idParam);
      setId(idParam);
    }
  }, [location]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage('');
  };

  const handleConfirmPwdChange = (e) => {
    setConfirmPwd(e.target.value);
    setErrorMessage('');
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrorMessage('');
  };

  // const { token, saveToken, logoutUser } = useAuth();


  const onSignup = async () => {
    // console.log("Email: ", email, "Password: ", password);
    setLaoding(true);
    if (email.trim() === "" || password.trim() === "" || name.trim() === "" || confirmPwd.trim() === "") {
      setErrorMessage("Please fill the required fields!");
      setLaoding(false);
      return;
    }
    if (password !== confirmPwd) {
      setErrorMessage("Passwords do not match!");
      setLaoding(false);
      return;
    }
    const picture = await uploadFile(profile);
    register(name, email, password, picture?.data?.url)
      .then(response => {
        if (response.success) {
          setTimeout(() => {
            // toast.success("Registration successful!");
          }, 400);
          if (id) {
            navigate(`/login?uuid=${id}`);
          } else {
            navigate(`/login`);
          }
          setLaoding(false);
        } else {
          setErrorMessage(response.error);
          setLaoding(false);

        }

      })
      .catch(error => {
        console.error("There was an error!", error);
        let errorMessage = error.response && error.response.data ? error.response.data.statusMessage : "Unexpected error occured during registration!";
        setErrorMessage(errorMessage);
        setLaoding(false);

      });
  };

  return (
    <div className='main_container' style={{ minHeight: "100vh" }}>
      <div className='screen_container justify-content-between'>
        <Header />
        <ToastContainer toastClassName='toaster' />
        <div className=''>
          <p className='text-black text-center my-2'>Register</p>
          {errorMessage && (
            <p className='text-danger mb-2 text-center' style={{ fontSize: '14px' }}>
              {errorMessage}
            </p>
          )}
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1'>
            <Form>
              <div className='d-flex justify-content-between'>
                <Form.Group className="mb-3 inputWidth" controlId="name">
                  <Form.Control type="text" placeholder="Name" className='input' value={name} onChange={handleNameChange} />
                </Form.Group>
                <Form.Group className="mb-3 inputWidth" controlId="email">
                  <Form.Control type="email" placeholder="Email Address" className='input' value={email} onChange={handleEmailChange} />
                </Form.Group>
              </div>
              <div className='d-flex justify-content-between'>
                <Form.Group className="mb-3 inputWidth" controlId="password">
                  <Form.Control type="password" placeholder="Password" className='input' value={password} onChange={handlePasswordChange} />
                </Form.Group>
                <Form.Group className="mb-3 inputWidth" controlId="confirmpassword">
                  <Form.Control type="password" placeholder="Confirm Password" className='input' value={confirmPwd} onChange={handleConfirmPwdChange} />
                </Form.Group>
              </div>
              <div>
                {
                  loading ? (
                    <LoadingIndicator />
                  ) : (
                    <Button className="w-100 actionBtn mb-1" type="button" onClick={onSignup}>
                      Sign up
                    </Button>
                  )
                }
              </div>
            </Form>
            <p className='text-black text-center my-2'>or Continue with</p>
            <div className='d-flex justify-content-between'>
              <Button className='w-50 fbbg me-4' id="facebook" type="button" onClick={async () => {
                const fb = await FBlogin();
                if (fb) {
                  setTimeout(() => {
                    // toast.success("You are login successfully!");
                  }, 500);
                  if (id) {
                    navigate(`/deceased-profile?uuid=${id}`);
                  } else {
                    navigate(`/profile`);
                  }
                }
              }}>
                <img src={facebook} alt='f' className='me-3 mb-1' /> Facebook
              </Button>
              <Button className='w-50 googlebg' id="google" type="button" onClick={async () => {
                const google = await googleLogin();
                // console.log(google);
                if (google) {
                  if (id) {
                    navigate(`/deceased-profile?uuid=${id}`);
                  } else {
                    navigate(`/profile`);
                  }
                  setTimeout(() => {
                    // toast.success("You are login successfully!");
                  }, 500);
                }
              }}>
                <img src={google} alt='f' className='me-3 mb-1' /> Google
              </Button>
            </div>
          </div>
          <div className='input_container mx-0 h-auto w-100 px-2 py-3 rounded-1 mt-3'>
            <p className='text-black text-center mb-2'>Already Have an Account?</p>
            <Button className='w-100 signupbtn mt-2 text-black' id="signupbtn" type="button" onClick={() => {
              if (id) {
                navigate(`/login?uuid=${id}`)
              } else {
                navigate('/login')
              }
            }}>
              Sign in
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup