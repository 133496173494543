import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/admin/Sidebar';
import NavBar from '../../components/admin/NavBar';
import { useLocation } from 'react-router-dom';
import AdminServices from '../../services/AdminServices';
import person from '../../assets/images/person.jpeg';
import { toast } from 'react-toastify';

function Users() {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const location = useLocation();

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await AdminServices.GetALLUsers(pageNumber);
                if (response) {
                    setUsers(response.data);
                    const pages = Math.floor(response.recordsTotal / 50) + 1;
                    setTotalPages(pages);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [pageNumber]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const searchQuery = params.get('search');
        if (searchQuery) {
            setSearchTerm(searchQuery);
        }
    }, [location]);

    const handleDeleteUser = async (id) => {

        setLoading(true);
        AdminServices.deleteUser(id).then(() => {
            const updatedUsers = users.filter(user => user.id !== id);
            setUsers(updatedUsers);
            setSuccess("User Deleted Successfully")
            setLoading(false);
        });
    };

    const filteredUsers = users.filter(user =>
        user.displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleNextPage = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    return (
        <div className='d-flex'>
            <Sidebar />
            <div className='container-fluid px-0 bg-light vh-100'>
                <NavBar />
                <div className='p-4 mb-3' style={{ width: "100%",height: 'calc(100% - 100px)', overflowY: 'auto' }}>
                    <h2 className='mb-4'>Users</h2>
                    {/* {error && <p className='text-danger text-center' style={{ fontSize: '12px' }}>{error}</p>} */}
                    {success && <p className='text-success text-center' style={{ fontSize: '12px' }}>{success}</p>}

                    <div className='mb-4 row justify-content-end'>
                        <div className='col-6 col-lg-4 col-xl-4 col-md-4'>
                            <input
                                type="text"
                                placeholder="Search users by name or email"
                                className="form-control w-100"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>

                    <table className='table table-hover shadow-sm rounded'>
                        <thead className='thead-light'>
                            <tr>
                                <th className='text-center'>Picture</th>
                                <th className='text-center'>User Name</th>
                                <th className='text-center'>Email</th>
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="5" className='text-center'>
                                        Loading users...
                                    </td>
                                </tr>
                            ) : (
                                filteredUsers.length > 0 ? (
                                    filteredUsers.map(user => (
                                        <tr key={user.id} className='text-center'>
                                            <td className='align-middle'>
                                                <img
                                                    src={user.imagePath ?? person}
                                                    alt={`${user.displayName}'s Profile`}
                                                    width="50"
                                                    height="50"
                                                    className='img-thumbnail rounded-circle'
                                                />
                                            </td>
                                            <td className='align-middle'>{user.displayName}</td>
                                            <td className='align-middle'>{user.email}</td>
                                            <td className='align-middle'>
                                                <button
                                                    className='btn btn-outline-danger rounded-pill'
                                                    onClick={() => handleDeleteUser(user.id)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className='text-center'>
                                            No users found
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>

                    <div className="d-flex justify-content-between">
                        <button
                            className="rounded-5 btn btn-outline-primary"
                            onClick={handlePreviousPage}
                            disabled={pageNumber === 0}
                        >
                            Previous
                        </button>
                        <span>Page {pageNumber + 1} of {totalPages}</span>
                        <button
                            className="rounded-5 btn btn-outline-primary"
                            onClick={handleNextPage}
                            disabled={pageNumber + 1 === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Users;
