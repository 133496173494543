import React from "react";
import styled from "styled-components";

const AboutWrapper = styled.div`
  padding: 0px 0px;
`;

const ProfileName = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  color: black
`;

const ProfileCategory = styled.span`
  color: #919191;
  font-size: 14px;
`;

const BioText = styled.span`
  display: block;
  margin-top: 1px;
  font-size: 14px;
  color: #000000
`;

function About({about}) {
  return (
    <AboutWrapper>
      {/* <ProfileName>Nelson Mandela</ProfileName> */}
      {/* <ProfileCategory>Former President of South Africa</ProfileCategory> */}
      <BioText>{about}</BioText>
    </AboutWrapper>
  );
}

export default About;
