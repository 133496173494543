import React from "react";

const CountCards = ({ data }) => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-evenly">
        {data.map((item, index) => (
          <div className="col-md-3" key={index}>
            <div
              className="card text-center shadow-sm card-hover"
              style={{
                color: "black",
                borderRadius: "20px",
                transition: "background 0.3s ease",
              }}
            >
              <div className="card-body">
                <div className="mb-3 p-2 iconcon rounded-circle mx-auto justify-content-center d-flex align-items-center">{item.icon}</div>
                <h5
                  className="card-title"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {item.title}
                </h5>
                <p
                  className="card-text"
                  style={{
                    fontSize: "2.5rem",
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  {item.count}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountCards;
