import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import { toast } from 'react-toastify';
import useAuthServices from './AuthServices';

const API_URL = `${BASE_URL}/api/Notifications`;

const useNotificationServices = () => {
    const { getToken } = useAuthServices();
    const token = getToken();

    // console.log("Token", token);

    const getNotifications = async (size) => {
        try {
            const response = await axios.post(
                `${API_URL}/PaginationList`,
                {
                    pageNumber: 0,
                    pageSize: size
                },
                {
                    headers: {
                        Authorization: `Bearer ${token.access_token}`
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error getting notifications:', error);
            // toast.error('Failed to retrieve notifications.');
            return false;
        }
    };

    const updateReadAt = async (id) => {
        try {
            const response = await axios.get(
                `${API_URL}/ReadNotfication?Id=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token.access_token}`
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error updating notification read status:', error);
            // toast.error('Failed to update notification status.');
            return false;
        }
    };

    return {
        getNotifications,
        updateReadAt
    };
};

export default useNotificationServices;

