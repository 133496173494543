import { useState } from "react";
import { toast } from "react-toastify";
import { auth, facebookProvider, googleProvider } from "../firebase/Firebase";
import { signInWithPopup } from "firebase/auth";
import useAuthServices from "../services/AuthServices";

const useSocialAuth = () => {
    const { socialSignin } = useAuthServices();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const FBlogin = async () => {
        setLoading(true);
        try {
            const result = await signInWithPopup(auth, facebookProvider);
            if (!result.user.email) {
                // toast.error("Something went wrong while authenticating with Facebook. Try other authentication methods");
                setLoading(false);
                return { success: false, error: "Something went wrong while authenticating with Facebook. Try other authentication methods" };
            }
            const response = await socialSignin(result.user.displayName, result.user.email, result.user.photoURL);
            setLoading(false);
            return response ? { success: true } : { success: false };
        } catch (error) {
            const errorMessage = error.response?.data?.statusMessage || "Error Occured Unexpectedly! Try again!";
            // toast.error(errorMessage);
            setError(error);
            setLoading(false);
            return { success: false, error: errorMessage };
        }
    };

    const googleLogin = async () => {
        setLoading(true);
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const response = await socialSignin(result.user.displayName, result.user.email, result.user.photoURL);
            setLoading(false);
            return response ? { success: true } : { success: false, error: "Error Occured Unexpectedly! Try again!" };
        } catch (error) {
            const errorMessage = error.response?.data?.statusMessage || "Error Occured Unexpectedly! Try again!";
            // toast.error(errorMessage);
            setError(error);
            setLoading(false);
            return { success: false, error: errorMessage };
        }
    };

    return { FBlogin, googleLogin, loading, error };
};

export default useSocialAuth;
