import './App.css';
import Navigator from './routes/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
      <Navigator />
  );
}

export default App;
